var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CatalogItems"),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { xs: "6", md: "6", sm: "6" } }, [
            _c("div", { staticClass: "px-2 primary--text font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$vuetify.lang.t("$vuetify.catalog.upload_catalog_items")
                  ) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-container",
        [
          _c("v-file-input", {
            attrs: {
              label: _vm.$vuetify.lang.t("$vuetify.project.select_file"),
              accept: ".csv",
              outlined: "",
              clearable: "",
            },
            model: {
              value: _vm.selectedFile,
              callback: function ($$v) {
                _vm.selectedFile = $$v
              },
              expression: "selectedFile",
            },
          }),
          _c(
            "div",
            { staticClass: "ml-8" },
            [
              _c(
                "v-alert",
                { attrs: { type: "info", text: "", border: "left" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        `${_vm.$vuetify.lang.t(
                          "$vuetify.project.dataset_update_info"
                        )}`
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "8" } }),
              _c(
                "v-col",
                { staticClass: "text-right", attrs: { cols: "12", md: "4" } },
                [
                  !this.inProgress
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleFileParsing()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                `${_vm.$vuetify.lang.t(
                                  "$vuetify.upload_btn_txt"
                                )}`
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _c(
                        "v-btn",
                        {
                          staticClass: "mr-4 secondary-disabled-button",
                          attrs: { color: "primary" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                `${_vm.$vuetify.lang.t(
                                  "$vuetify.project.uploading"
                                )}`
                              ) +
                              " "
                          ),
                          _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              size: 15,
                              width: 2,
                              color: "white",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.updateResult !== "" || _vm.taskHistoryData !== null
            ? [
                _c("v-divider", { staticClass: "mt-8" }),
                _c(
                  "v-card",
                  { staticClass: "elevation-0" },
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "text-h6 font-weight-bold" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              `${_vm.$vuetify.lang.t(
                                "$vuetify.project.dataset_update_log"
                              )}`
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("v-divider"),
                    _c(
                      "div",
                      [
                        [
                          _c("v-data-table", {
                            attrs: {
                              loading: _vm.isTrainHistoryLoading,
                              headers: _vm.trainHistoryHeaders,
                              items: _vm.taskHistoryData,
                              "sort-desc": [true],
                              "server-items-length": _vm.totalNumberOfTrains,
                              "footer-props": {
                                itemsPerPageOptions: _vm.itemsPerPageOptions,
                              },
                              "items-per-page":
                                _vm.defaultPagination.itemsPerPage,
                              page: _vm.defaultPagination.page,
                            },
                            on: {
                              "update:itemsPerPage": function ($event) {
                                return _vm.$set(
                                  _vm.defaultPagination,
                                  "itemsPerPage",
                                  $event
                                )
                              },
                              "update:items-per-page": [
                                function ($event) {
                                  return _vm.$set(
                                    _vm.defaultPagination,
                                    "itemsPerPage",
                                    $event
                                  )
                                },
                                _vm.handleItemsPerPageChange,
                              ],
                              "update:page": [
                                function ($event) {
                                  return _vm.$set(
                                    _vm.defaultPagination,
                                    "page",
                                    $event
                                  )
                                },
                                _vm.handlePageChange,
                              ],
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.name",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.name ? item.name : "N/A"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.started_at",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.started_at
                                              ? _vm.formatDateTime(
                                                  item.started_at
                                                )
                                              : "N/A"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.last_updated_at",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.last_updated_at
                                              ? _vm.formatDateTime(
                                                  item.last_updated_at
                                                )
                                              : "N/A"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.message",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.message ? item.message : "N/A"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.scheduled_by",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.scheduled_by
                                              ? item.scheduled_by
                                              : "N/A"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.status",
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.status ? item.status : "N/A"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              225679636
                            ),
                          }),
                        ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }